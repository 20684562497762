.Footer {
  background: #c4c4c4;
  display: flex;
}

span {
  display: inline;
  margin: 2px;
  color: #747474;
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.Logo {
  margin-bottom: -30px;
  width: 130px;
  height: 130px;
  margin-top: -30px;
}
.Contact1 {
  margin: 5px 20px;
  padding: 15px;
  display: flex;
  flex-direction: column;
}
i {
  color: rgb(73, 72, 72);
}
.Icons {
  margin-right: 7px;
  width: 20px;

  bottom: 2px;
  height: 20px;
}
.Contact2 {
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 20px 200px 5px 150px;
  text-align: left;
}
.Content {
  margin: 10px;
}
.Icons:hover {
  color: rgb(51, 51, 51);
}
.Copy {
  margin: 3px;
}
.AdContainer {
  display: flex;
  flex-direction: row;
  color: #747474;
  margin: 6px;
}
.Address {
  display: flex;
}
.Copyright2 {
  margin: 20px;
  color: #747474;
}
.Copyright1 {
  margin-top: -20px;
  color: #747474;
}
.Mail {
  color: #747474;
  text-decoration: none;
}
.Mail:hover {
  color: #444444;
  text-decoration: none;
}
/* MOBILE */

@media only screen and (max-width: 500px) {
  .Footer {
    flex-direction: column-reverse;
  }
  .Contact2 {
    display: flex;
    flex-direction: column;
    /* border: 1px solid rgb(10, 10, 10); */
    padding: 10px;
    margin: 20px;
    margin-left: -5px;
    /* margin-bottom: 20px; */
    text-align: left;
  }
  .Contact1 {
    margin: 5px;
    margin-bottom: -30px;
    text-align: center;
  }

  .Icons {
    margin-right: 8px;
    margin-bottom: -8px;
  }
  .Copyright1 {
    margin-top: -25px;
  }
}

/* TABLETS */
@media only screen and (min-width: 738px) and (max-width: 900px) {
  .Footer {
    flex-direction: column-reverse;
  }
  .Contact2 {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 20px;
    margin-left: -5px;
    text-align: left;
    font-size: 1.5em;
  }
  .Contact1 {
    margin: 5px;
    margin-bottom: -30px;
    text-align: center;
    font-size: 1.5em;
  }

  .Icons {
    margin-right: 8px;
    margin-bottom: -8px;
  }
  .Copyright1 {
    margin-top: -25px;
  }
}

@media only screen and (min-width: 901px) and (max-width: 1100px) {
  .Footer {
    flex-direction: column-reverse;
  }
  .Contact2 {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 20px;
    margin-left: -5px;
    text-align: left;
    font-size: 1.8em;
  }
  .Contact1 {
    margin: 5px;
    margin-bottom: -30px;
    text-align: center;
    font-size: 1.8em;
  }

  .Icons {
    margin-right: 8px;
    margin-bottom: -8px;
    height: 30px;
    width: 30px;
  }
  .Copyright1 {
    margin-top: -25px;
  }
}
