.Container {
  display: flex;
  flex-direction: column;
  background-color: #e5e5e5;
  width: 100%;
}
.Specialization h2 {
  font-size: 50px;
  margin-top: 70px;
}
#Head {
  margin: -5rem 0 7rem -40vw;
}
.rightSec1 h5 {
  font-family: "Playfair Display", sans-serif;
  font-weight: 500;
  font-size: 30px;
  text-align: left;
  margin-top: 70px;
  margin-left: -3%;

  /* margin-left: 45px; */
}
.rightSec1 p {
  text-align: left;
  font-size: 22px;
  margin-left: 45px;
}
.leftSec2 h5 {
  font-family: "Playfair Display", sans-serif;
  font-weight: 500;
  font-size: 30px;
  text-align: right;
  margin-right: -5%;
  margin-top: 80px;
}
.leftSec1 img {
  width: 350px;
  height: 300px;
}
.rightSec2 img {
  width: 350px;
  height: 300px;
}
.leftSec2 p {
  text-align: right;
  margin-right: 2%;
}
.Section {
  display: flex;
  flex-direction: row;
  padding: 40px;
  width: 100%;
  margin: -75px auto;
  margin-left: 45px;
}
.leftSec1 {
  width: 35%;
}
.rightSec1 {
  width: 60%;
  margin-right: -20px;
}
p {
  margin-top: -7px;
  color: #747474;
}
.leftSec2 {
  width: 60%;
  margin-left: -20px;
  margin-top: 10px;
}
.rightSec2 {
  width: 40%;
  padding: 2px;
}
.headingStyle {
  font-size: 40px;
  font-weight: 500;
  display: flex;
  margin-bottom: 120px;
  margin-top: 50px;
  text-align: left;
  margin-left: 80px;
}
.headingLine {
  display: inline-block;
  flex-grow: 1;
  height: 4px;
  background-color: #747474;
  margin-top: 30px;
  margin-left: 18px;
}

/* Mobile */

@media only screen and (max-width: 600px) {
  .Container {
    display: flex;
    flex-direction: column;
    background-color: #e5e5e5;
    padding: 40px 0;
  }
  .Specialization h2 {
    font-size: 25px;
    margin-top: 20px;
  }
  .rightSec1 h5 {
    font-family: "Playfair Display", sans-serif;
    font-weight: 500;
    font-size: 1.4em;
    text-align: left;
    margin-left: 40%;
    margin-top: 15px;
  }
  .rightSec1 p {
    text-align: left;
    margin-left: 40%;
    font-size: 15px;
  }
  .leftSec2 h5 {
    font-family: "Playfair Display", sans-serif;
    font-weight: 500;
    font-size: 1.4em;
    text-align: right;
    margin-right: 15%;
    margin-top: 20px;
    text-align: left;
  }
  .leftSec1 img {
    width: 140px;
    height: 140px;
  }
  .rightSec2 img {
    width: 140px;
    height: 140px;
  }
  .leftSec2 p {
    text-align: right;
    margin-right: 15%;
    text-align: left;
  }
  .Section {
    display: flex;
    flex-direction: row;
    margin: 25px 10px 25px 40px;
    width: 100%;
    padding: 0;
  }
  .leftSec1 {
    width: 20%;
  }
  .rightSec1 {
    width: 60%;
    margin-left: -10px;
    margin-top: 10px;
  }
  p {
    margin-top: -7px;
    color: #747474;
  }
  .leftSec2 {
    width: 50%;
    margin-right: -20px;
    margin-top: 10px;
    margin-left: 5px;
  }
  .rightSec2 {
    width: 20%;
  }
  .headingStyle {
    font-size: 25px;
    font-weight: 500;
    display: flex;
    margin-bottom: 35px;
    margin-top: 50px;
    text-align: left;
    margin-left: 10px;
  }
  .headingLine {
    display: inline-block;
    flex-grow: 1;
    height: 4px;
    background-color: #747474;
    margin-top: 18px;
    margin-left: 18px;
  }
}

/* Tablets size */
@media only screen and (min-width: 738px) and (max-width: 900px) {
  .Container {
    display: flex;
    flex-direction: column;
    background-color: #e5e5e5;
    padding: 60px 0;
  }
  .Specialization h2 {
    font-size: 2em;
    margin-top: 20px;
  }
  .rightSec1 h5 {
    font-family: "Playfair Display", sans-serif;
    font-weight: 500;
    font-size: 1.8em;
    text-align: left;
    margin-left: 10%;
    margin-top: 50px;
    text-align: left;
  }
  .rightSec1 p {
    text-align: left;
    margin-left: 40%;
    font-size: 18px;
    text-align: left;
  }
  .leftSec2 h5 {
    font-family: "Playfair Display", sans-serif;
    font-weight: 500;
    font-size: 1.8em;
    text-align: right;
    margin-right: 20%;
    margin-top: 40px;
    /* text-align: left; */
  }
  .leftSec1 img {
    width: 250px;
    height: 200px;
  }
  .rightSec2 img {
    width: 250px;
    height: 200px;
  }
  .leftSec2 p {
    text-align: right;
    margin-right: 15%;
    text-align: left;
  }
  .Section {
    display: flex;
    flex-direction: row;
    margin: 25px 10px 25px 40px;
    width: 100%;
    padding: 0;
  }
  .leftSec1 {
    width: 40%;
  }
  .rightSec1 {
    width: 60%;
    margin-left: -10px;
    margin-top: 10px;
  }
  p {
    margin-top: -7px;
    color: #747474;
  }
  .leftSec2 {
    width: 50%;
    margin-right: -20px;
    margin-top: 10px;
    margin-left: 5px;
  }
  .rightSec2 {
    width: 20%;
  }
  .headingStyle {
    font-size: 25px;
    font-weight: 500;
    display: flex;
    margin-bottom: 35px;
    margin-top: 50px;
    text-align: left;
    margin-left: 10px;
  }
  .headingLine {
    display: inline-block;
    flex-grow: 1;
    height: 4px;
    background-color: #747474;
    margin-top: 18px;
    margin-left: 18px;
  }
}
@media only screen and (min-width: 901px) and (max-width: 1100px) {
  .Container {
    display: flex;
    flex-direction: column;
    background-color: #e5e5e5;
    padding: 40px 0;
  }
  .Specialization h2 {
    font-size: 3em;
    margin-top: 20px;
  }
  .rightSec1 h5 {
    font-family: "Playfair Display", sans-serif;
    font-weight: 500;
    font-size: 2.2em;
    text-align: left;
    margin-left: 10%;
    margin-top: 50px;
  }
  .rightSec1 p {
    text-align: left;
    margin-left: 40%;
    font-size: 15px;
  }
  .leftSec2 h5 {
    font-family: "Playfair Display", sans-serif;
    font-weight: 500;
    font-size: 2.2em;
    text-align: right;
    margin-right: 15%;
    margin-top: 50px;
    /* text-align: left; */
  }
  .leftSec1 img {
    width: 350px;
    height: 250px;
  }
  .rightSec2 img {
    width: 350px;
    height: 250px;
  }
  .leftSec2 p {
    text-align: right;
    margin-right: 15%;
    text-align: left;
  }
  .Section {
    display: flex;
    flex-direction: row;
    margin: 25px 10px 25px 40px;
    width: 100%;
    padding: 0;
  }
  .leftSec1 {
    width: 40%;
  }
  .rightSec1 {
    width: 60%;
    margin-left: -10px;
    margin-top: 10px;
  }
  p {
    margin-top: -7px;
    color: #747474;
  }
  .leftSec2 {
    width: 50%;
    margin-right: -20px;
    margin-top: 10px;
    margin-left: 5px;
  }
  .rightSec2 {
    width: 40%;
  }
  .headingStyle {
    font-size: 25px;
    font-weight: 500;
    display: flex;
    margin-bottom: 35px;
    margin-top: 50px;
    text-align: left;
    margin-left: 10px;
  }
  .headingLine {
    display: inline-block;
    flex-grow: 1;
    height: 4px;
    background-color: #747474;
    margin-top: 18px;
    margin-left: 18px;
  }
}

@media screen and (min-width: 1101px) {
  .Container {
    height: 2546px;
  }
}
