.button {
  padding: 6px 20px;
  text-decoration: none;
  font-size: 22px;
  color: #202020;
  border: 1px solid #444444;
}
.button:hover {
  background-color: #444444;
  color: #ffffff;
  text-decoration: none;
  transition-duration: 0.4s;
}
@media screen and (min-width: 500px) {
  .button {
    padding: 20px 50px;
    font-size: 28px;
    border: 2px solid #4444445d;
  }
}
