.Container {
  display: flex;
  flex-direction: column;
  background-color: #e5e5e5;
  padding-top: 50px;
  padding-bottom: 40px;
  justify-content: space-between;
}
.Clients h2 {
  font-size: 50px;
  margin-bottom: 50px;
}
.Section {
  display: flex;
  width: 100%; /*
  margin: auto;
  margin-left: 45px;*/
  align-items: center;
  justify-content: center;
}
.Content {
  margin: 50px;
  width: 15%;
}
.Image {
  width: 120px;
  height: 120px;
}
@media only screen and (max-width: 1300px) {
  .Content {
    margin: 5vw 3vw;
    width: 22%;
  }
  .Clients h2 {
    font-size: 6vh;
    margin-bottom: 6vh;
  }
}
@media only screen and (max-width: 500px) {
  .Container {
    display: flex;
    flex-direction: column;
    background-color: #e5e5e5;
    padding-top: 40px;
    padding: 10px;
  }
  .Section {
    display: flex;
    width: 100%;
    margin: 10px auto;
  }
  .Content {
    margin: 5vw 3vw;
    width: 22%;
  }
  .Image {
    width: 15vw;
    height: 10vh;
  }
  .Clients h2 {
    font-size: 7vh;
    margin-bottom: 6vh;
  }
}
