.mainContainer {
  position: fixed;
  z-index: 10;
  top: 0px;
  width: 100%;
  height: 70px;
  background-size: cover;
  background-color: transparent;
}
.mainContainerscroll {
  position: fixed;
  z-index: 10;
  top: 0px;
  width: 100%;
  height: 70px;
  background-size: cover;
  background-color: #f2f2f2;
}
* {
  margin: none;
}
.menu_div {
  transition: 0.5s all ease-in-out;
  position: fixed;
  z-index: 101;
  background: #e5e5e5;
  text-align: left;
  width: 100%;
  margin: auto;
  /*padding-top: 20vh;*/
  height: 100vh;
}
.menu_div div {
  background-size: cover;
}
.menu_div ul {
  animation: 0.5s ease-in-out;
  width: 90%;
  margin: auto;
  padding-top: 20vh;
  list-style: none;
}

.menu_div li {
  font-family: "Playfair Display", sans-serif;
  font-weight: 600;
  font-size: 2.2em;
  margin-bottom: 10%;
  color: #444444;
}
.logoContainer.open {
  position: fixed;
}

.logoContainer {
  float: left;
  margin-top: 15px;
  margin-left: 15px;
  z-index: 103;
}
.logo {
  position: static;
}
.navMenuContainer {
  position: relative;
  float: right;
  margin: 2 px;
  z-index: 103;
  margin-top: 0px;
}
.navMenuContainer img {
  display: block;
}
.navMenuContainer ul {
  display: none;
}
.menu_btn {
  justify-content: center;
  align-items: center;
  position: relative;
  display: flex;
  width: 60px;
  height: 60px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  border: 0px solid #f2f2f2;
}

.menu_btn_ham {
  width: 40px;
  height: 4px;
  background: #747474;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}
.menu_btn_ham::before {
  width: 55px;
}
.menu_btn_ham::after {
  width: 25px;
}
.menu_btn_ham::before,
.menu_btn_ham::after {
  content: "";
  position: absolute;
  height: 4px;
  background: #747474;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
  right: 10px;
}
.menu_btn_ham::before {
  transform: translateY(-13px);
  transition: all 0.5s ease-in-out;
}
.menu_btn_ham::after {
  transition: all 0.5s ease-in-out;
  transform: translateY(13px);
}
.menu_btn.open .menu_btn_ham {
  transform: translateX(-40px);
  background: transparent;
  transition: all 0.5s ease-in-out;
  position: fixed;
}
.menu_btn.open .menu_btn_ham::before {
  transition: all 0.5s ease-in-out;
  position: fixed;
  width: 40px;
  transform: rotate(45deg) translate(38px, -38px);
}
.menu_btn.open .menu_btn_ham::after {
  transition: all 0.5s ease-in-out;
  transform: rotate(-45deg) translate(38px, 38px);
  position: fixed;
  width: 40px;
}
.subtext {
  position: relative;
  text-align: center;
  top: 200px;
}

@media screen and (min-width: 970px) {
  .menu_btn {
    display: none;
  }
  .menu_div {
    display: none;
  }
  .mainContainer {
    height: 90px;
  }
  .mainContainerscroll {
    height: 90px;
  }
  .logoContainer {
    margin-top: 20px;
    margin-left: 34px;
  }
  .logo {
    height: 50px;
  }
  .navMenuContainer img {
    display: none;
  }
  .navMenuContainer ul {
    display: block;
    float: right;
    margin-top: 15px;
  }
  .navMenuContainer ul li {
    display: inline-block;
    margin-left: 20px;
    margin-right: 20px;
  }
  .navMenuContainer ul li a {
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    color: #747474;
  }
  .navMenuContainer ul li a:hover {
    color: black;
    transition-duration: 0.4s;
  }
  .navMenuContainer ul li Link {
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    color: #747474;
  }
  .navMenuContainer ul li Link:hover {
    color: black;
    transition-duration: 0.4s;
  }
}
@media screen and (min-width: 1090px) {
  .navMenuContainer ul li {
    margin-left: 30px;
    margin-right: 30px;
  }
}
