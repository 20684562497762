@media only screen and (min-width: 501px) {
  .scrollspy {
    border: 1px solid black;
    position: fixed;
    background: purple;
    bottom: 570px;
    width: 100px;
    height: 100px;
    left: -50px;
    border-radius: 100px;
  }
  .AboutUs {
    background-color: #e5e5e5;
    min-height: 80vh;
    padding-bottom: 100px;
  }
  .Info {
    margin: 50px auto;
    margin-bottom: 0px;
    color: #787878;
    width: 774px;
    font-size: 18px;
    text-align: justify;
    line-height: 42px;
  }
  .AboutUs h2 {
    padding-top: 6%;
    font-size: 48px;
    font-weight: 400;
  }
  .Image {
    width: 100%;
    position: relative;
    bottom: 650px;
  }
}

/* Mobile */

@media only screen and (max-width: 500px) {
  .AboutUs {
    background: #e5e5e5;
    min-height: 60vh;
  }
  .AboutUs h2 {
    padding-top: 30px;
    font-size: 26px;
    margin: 20px;
  }
  .Info {
    padding: 40px;
    width: 100vw;
    min-height: 50vh;
    margin: 10px;

    text-align: justify;
    line-height: 30px;
    font-size: 14px;
    color: #747474;
  }
  .Carousel {
    margin: 20px;
  }
  .Image {
    width: 100%;
    margin-bottom: -250px;
  }
  .Work {
    margin: 80px;
    background-color: #fff;
    width: 70%;
  }
}
@media only screen and (min-width: 738px) and (max-width: 900px) {
  .AboutUs {
    background: #e5e5e5;
    padding-bottom: 150px;
  }
  .AboutUs h2 {
    padding-top: 30px;
    font-size: 3em;
    margin: 20px;
    margin-bottom: 30px;
  }
  .Info {
    padding: 40px;
    width: 700px;
    margin: 30px 20px;
    text-align: justify;
    font-size: 1.6em;
    color: #747474;
  }
  .Carousel {
    margin: 20px;
  }
  .Image {
    width: 100%;
    margin-bottom: 250px;
    position: unset;
  }
  .Work {
    margin: 80px;
    background-color: #fff;
    width: 70%;
  }
}
@media only screen and (min-width: 901px) and (max-width: 1100px) {
  .AboutUs {
    background: #e5e5e5;
    padding-bottom: 150px;
  }
  .AboutUs h2 {
    padding-top: 30px;
    font-size: 4em;
    margin: 20px;
    margin-bottom: 30px;
  }
  .Info {
    padding: 40px;
    width: 90%;
    margin: 30px 40px;
    text-align: justify;
    font-size: 2em;
    color: #747474;
  }
  .Carousel {
    margin: 20px;
  }
  .Image {
    width: 100%;
    margin-bottom: 250px;
    position: unset;
  }
  .Work {
    margin: 80px;
    background-color: #fff;
    width: 70%;
  }
}
