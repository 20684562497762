* {
  font-family: "Poppins";
  scroll-behavior: smooth;
}
.close {
  border: none;
  background-color: transparent;
}
.close:hover {
  color: rgba(0, 0, 0, 0.315);
  transition-duration: 0.4s;
}
.majorcontainer {
  padding-top: 70px;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.majorcontainer h2 {
  font-weight: 400;
  padding-top: 0px;
  color: #444444;
}

.bioCore {
  position: fixed;
  left: 50%;
  margin-left: -37%;
  top: 50vh;
  margin-top: -35vh;
  height: 0vh;
  width: 75%;
  transition-duration: 0s;
  overflow: hidden;
}
.bioCore p {
  position: absolute;
  background-color: #ffffff;
  text-align: justify;
  padding: 20px 50px;
  padding-top: 30px;
  font-size: 20px;
  font-weight: 300;
  line-height: 45px;
  color: #272727;
  height: 70vh;
  border-radius: 15px;
  overflow: auto;
  opacity: 0;
  transition-duration: 0.4s;
}
::-webkit-scrollbar {
  background-color: #e4e4e4;
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: #747474;
}
.closeicon:hover {
  cursor: pointer;
  color: #242424;
  transition-duration: 0.4s;
}

.Members tr td {
  padding-top: 37px;
}
.Members tr td img {
  border-radius: 100%;
}
.Members tr td img:hover {
  cursor: pointer;
  transition-duration: 0.9s;
  box-shadow: 3px 3px 10px 10px rgba(0, 0, 0, 0.06);
}
.imgAdjustR {
  padding-right: 41px;
}
.imgAdjustL {
  padding-left: 41px;
}
.name {
  font-family: "Playfair Display";
  margin: 12px 0px;
}
.name b {
  font-family: "playfair Display";
}
.post {
  width: 117px;
  font-size: 14px;
  color: #444444;
}
.otherMembers {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.otherMembers div {
  flex: 0 100%;
  flex-grow: 1;
  margin: 10px 5%;
  justify-content: center;
}
.otherMembers div p {
  width: 100%;
  font-size: 18px;
  line-height: 40px;
  font-weight: 400;
  font-family: "playfair display";
  margin: 0px;
}
.post1 {
  font-size: 14px;
  color: #444444;
}
.post2 {
  font-size: 14px;
  font-weight: 250;
  color: #444444;
  text-align: center;
  margin-bottom: 10px;
}
.otherMembers div p b {
  font-family: "playfair display";
}
.TopButton {
  position: fixed;
  z-index: 100;
  right: 5%;
  bottom: 10%;
  background: white;
  padding: 10px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 1px 5px 0 rgba(0, 0, 0, 0.06);
  border-radius: 50%;
  opacity: 0.5;
  transition: 0.3s all ease-in-out;
}
.TopButton:hover {
  opacity: 1;
}

@media screen and (min-width: 850px) {
  .majorcontainer h2 {
    font-weight: 800;
    font-size: 35px;
    padding-top: 30px;
    padding-bottom: 20px;
  }
  .Members tr td img {
    width: 170px;
  }
  .imgAdjustR {
    padding-right: 130px;
  }
  .imgAdjustL {
    padding-left: 130px;
  }
  .name {
    font-size: 25px;
  }
  .post {
    font-size: 18px;
    width: 312px;
    font-weight: 300;
  }
  .post2 {
    width: 400px;
  }
  .otherMembers {
    padding-bottom: 100px;
  }
  .otherMembers div {
    flex: 0 40%;
    box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.205);
  }
  .otherMembers div p {
    font-size: 25px;
    line-height: 70px;
  }
}
@media only screen and (max-width: 850px) {
  .bioCore p {
    position: absolute;
    text-align: justify;
    padding: 20px 30px;
    padding-top: 10px;
    font-size: 18px;
    font-weight: 300;
    line-height: 35px;
    color: #303030;
    height: 70vh;
    border-radius: 15px;
    overflow: auto;
    opacity: 0;
    transition-duration: 0.4s;
  }
  .bioCore {
    left: 50%;
    margin-left: -47%;
    top: 50vh;
    margin-top: -35vh;
    height: 0vh;
    width: 95%;
    transition-duration: 0s;
    overflow: hidden;
  }
}
@media only screen and (min-width: 900px) and (max-width: 1030px) {
  .bioCore p {
    font-size: 27px;
    line-height: 55px;
  }
}
.LeftAlign{
  text-align: left;
  margin-left: 100px;
}
ol {
  background: #ff9999;
  padding: 20px;
}

ul {
  padding: 20px;
  line-height: 35px;
}

ol li {
  background: #ffe5e5;
  color: darkred;
  padding: 5px;
  margin-left: 35px;
}
.ImageHeight{
height: 250px !important;
}
.ImageHeightNew{
  height: 300px !important;
  width: 350px !important;
  padding: 2px !important;
  }