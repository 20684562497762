@media only screen and (max-width: 850px) {
  .Container1 {
    display: flex;
    flex-direction: column;
    /* margin-top: 80px; */
    background-color: #e5e5e5;
  }

  .Section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .Content {
    margin: 10px 50px;
    flex: 0 20%;
    flex-grow: 1;
  } /*
  .Content p {
    margin-top: -50px;
    margin-left: 5px;
    color: rgb(0, 0, 0);
    font-size: 14px;
    text-align: left;
  }*/
  .headingStyle {
    font-size: 25px;
    font-weight: 500;
    display: flex;
    margin-top: 50px;
    text-align: right;
    margin-right: 10px;
    padding-right: 15px;
  }
  .headingLine {
    display: inline-block;
    flex-grow: 1;
    height: 4px;
    background-color: #747474;
    margin-top: 18px;
    margin-right: 20px;
  }
  .Container1 img {
    display: none;
  }
  #aocspider{
    display: block;
    width: 100vw;
  }
  .sec1 div:nth-child(2) {
    margin: 0px 20px;
  }
  .sec1 div:nth-child(1) {
    display: none;
  }
  .sec2 div:nth-child(1) {
    margin: 0px 20px;
  }
  .sec2 div:nth-child(2) {
    display: none;
  }
  .service1,
  .service2 {
    margin-left: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: left;
  }
  hr {
    display: none;
  }
}
@media screen and (min-width: 850px) {
  .Container1 {
    display: flex;
    flex-direction: column;
    /* margin-top: 80px; */
    background-color: #e5e5e5;
    padding-bottom: 60px;
  }
  .headingStyle {
    font-size: 40px;
    font-weight: 500;
    display: flex;
    margin-bottom: 70px;
    margin-top: 150px;
    text-align: right;
    margin-right: 10px;
    padding-right: 15px;
  }
  .headingLine {
    display: inline-block;
    flex-grow: 1;
    height: 4px;
    background-color: #747474;
    margin-top: 28px;
    margin-right: 20px;
  }
  .sec1 {
    display: flex;
    flex-wrap: nowrap;
    flex-flow: row;
    margin-bottom: 50px;
  }
  .sec2 {
    display: flex;
    flex-wrap: nowrap;
    flex-flow: row;
    margin-top: 70px;
    margin-bottom: 50px;
  }
  .sec1 img {
    width: 100%;
    overflow: hidden;
    transition: 0.3s ease-in-out;
  }
  .parent {
    margin: 140px 30px 10px 70px;
    width: 600px;
    height: 400px;
    overflow: hidden;
  }
  .sec1 div:nth-child(2) {
    width: 50%;
    display: flex;
    flex-flow: column;
    justify-content: center;
  }
  .sec2 img {
    width: 100%;
    overflow: hidden;
    transition: 0.3s ease-in-out;
  }
  .sec2 div:nth-child(1) {
    width: 50%;
    margin-left: 95px;
    display: flex;
    flex-flow: column;
    justify-content: center;
  }
  .service1 {
    text-align: left;
    font-family: "Playfair Display", serif;
    font-size: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    color: #444444;
  }
  .service2 {
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: right;
    font-family: "Playfair Display", serif;
    font-size: 20px;
    color: #444444;
  }
  .para {
    margin: 50px 5%;
  }
}
hr {
  margin: 0;
}

@media screen and (min-width: 2000px) {
  .sec1 {
    display: flex;
    flex-wrap: nowrap;
    flex-flow: row;
    margin-top: 70px;
    margin-bottom: 50px;
    margin-left: 8%;
  }
  .sec2 {
    display: flex;
    flex-wrap: nowrap;
    flex-flow: row;
    margin-top: 70px;
    margin-bottom: 50px;
    margin-left: 8%;
  }
}

.Specialization h2 {
  font-size: 50px;
  margin-top: 70px;
}
@media only screen and (max-width: 600px) {
  .Specialization h2 {
    font-size: 25px;
    margin-top: 20px;
  }
}
@media only screen and (min-width: 738px) and (max-width: 900px) {
  .Specialization h2 {
    font-size: 2em;
    margin-top: 20px;
  }
}
@media only screen and (min-width: 901px) and (max-width: 1100px) {
  .Specialization h2 {
    font-size: 3em;
    margin-top: 20px;
  }
}
