.knowMore {
  text-align: left;
  padding-left: 30px;
  padding-top: 10px;
  font-weight: 400;
  color: #747474d0;
}
#Head {
  margin: 3rem;
  margin-left: -30vw;
}
.knowMore:hover {
  cursor: pointer;
  color: #747474;
  transition-duration: 0.4s;
}
.pointContainerMobO ul {
  margin-top: 12px;
}
.pointContainerMobC ul {
  margin-top: 12px;
}
.subline {
  text-align: center;
  margin: 50px 12px;
  font-size: 14px;
}
@media only screen and (max-width: 800px) {
  .knowMore {
    font-size: 10px;
  }
  .pointContainerMobO {
    position: absolute;
    width: 90%;
    height: 245px;
    background-color: #cfcfcff8;
    z-index: 1;
    overflow-y: auto;
    font-size: 15px;
    text-align: justify;
    margin: -1px;
    margin-left: -45%;
    padding-right: 5%;
    padding-left: 5%;
    font-weight: 300;
    line-height: 31px;
    border-radius: 8px;
    opacity: 10;
    transition-duration: 0.4s;
  }
  .pointContainerMobC {
    position: absolute;
    width: 90%;
    height: 0px;
    background-color: #cfcfcff8;
    z-index: 1;
    overflow-y: auto;
    font-size: 15px;
    text-align: justify;
    margin: -1px;
    margin-left: -45%;
    padding-right: 5%;
    padding-left: 5%;
    font-weight: 300;
    line-height: 31px;
    border-radius: 8px;
    opacity: 0;
    transition-duration: 0.4s;
  }
  .Container {
    background-color: #fff;
    margin-top: 40px;
    margin-bottom: 50px;
    padding: 10px;
    width: 100%;
  }
  .Work h5 {
    font-family: "Playfair Display", serif;
    font-weight: 500;
    font-size: 30px;
  }
  .work_box {
    display: flex;
    flex-wrap: nowrap;
    flex-flow: row;
    margin: auto;
    padding: 35px 15px;
  }
  .work_box div:nth-child(1) {
    width: 50%;
  }
  .work_box div:nth-child(2) {
    width: 50%;
    display: flex;
    flex-flow: column;
    justify-content: center;
  }
  #line {
    height: 2px;
    width: 190px;
    margin-left: 25px;
    margin-top: 3px;
    background: #747474;
  }
  .work_box img {
    width: 170px;
  }
  .work_box h5 {
    font-family: "Playfair Display", serif;
    font-size: 20px;
    color: #444444;
  }
}

@media only screen and (min-width: 801px) {
  .pointContainerMobO {
    position: absolute;
    width: 780px;
    height: 315px;
    background-color: #cfcfcff8;
    z-index: 1;
    overflow-y: auto;
    font-size: 18px;
    text-align: justify;
    margin: -1px;
    margin-left: -390px;
    padding-right: 5%;
    padding-left: 5%;
    font-weight: 300;
    line-height: 31px;
    border-radius: 8px;
    opacity: 10;
    transition-duration: 0.4s;
  }
  .pointContainerMobC {
    position: absolute;
    width: 780px;
    height: 0px;
    background-color: #cfcfcff8;
    z-index: 1;
    overflow-y: auto;
    font-size: 18px;
    text-align: justify;
    margin: -1px;
    margin-left: -390px;
    padding-right: 5%;
    padding-left: 5%;
    font-weight: 300;
    line-height: 31px;
    border-radius: 8px;
    opacity: 0;
    transition-duration: 0.4s;
  }
  /*.pointContainerMobC {
    display: none;
  }
  .pointContainerMobO {
    display: none;
  }*/
  .Container {
    background-color: #fff;
    margin-top: 40px;
    margin-bottom: 50px;
    padding: 5%;
    width: 100%;
  }
  .Work h5 {
    font-family: "Playfair Display", serif;
    font-weight: 500;
    font-size: 48px;
  }
  .work_box {
    display: flex;
    flex-wrap: nowrap;
    flex-flow: row;
    margin: auto;
    padding: 7vh 15px;
  }
  .work_box div:nth-child(1) {
    width: 50%;
  }
  .work_box div:nth-child(2) {
    width: 50%;
    display: flex;
    flex-flow: column;
    justify-content: center;
  }
  #line {
    height: 3px;
    width: 100vh;
    margin-left: 25px;
    margin-top: 3px;
    background: #747474;
  }
  .work_box img {
    width: 80%;
    margin-left: 40%;
  }
  .work_box h5 {
    font-family: "Playfair Display", serif;
    font-size: 28px;
    text-align: left;
    color: #444444;
  }
}
