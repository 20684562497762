.introPart1 {
  background-color: #f2f2f2;
  padding-top: 70px;
  padding-bottom: 20px;
}
.introPart2 {
  background-color: #f2f2f2;
  padding-bottom: 150px;
}

.introPart3 {
  margin-top: -150px;
}
.HeadingDesign {
  text-align: left;
  padding: 20px;
}
.backgroundText {
  font-size: 42px;
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  opacity: 0.3;
  margin: 0px;
  line-height: 63px;
  -webkit-text-stroke: 1px #747474;
  -webkit-text-fill-color: #f2f2f2;
}
.frontText {
  position: absolute;
  color: #747474;
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  margin-top: -170px;
}
.buttonContainer {
  margin: 20px;
  margin-left: 0px;
}
.buttonContainer a {
  font-family: "playfair display";
  font-weight: 400;
}
.workIntro {
  padding-top: 20px;
}
.workIntro p {
  color: #747474;
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
  margin-left: 26px;
  margin-right: 26px;
  text-align: justify;
}
.ManImageContainer {
  margin-bottom: 62px;
}
.buildingImageContainer img {
  width: 100%;
}
.carousel {
  margin-left: 10px;
  margin-right: 10px;
  overflow-y: hidden;
  height: 210px;
}
.introLastText {
  text-align: left;
  display: flex;
}
.introLastText p {
  display: inline-block;
  margin-top: 31px;
  margin-bottom: 27px;
  margin-left: 20px;
  margin-right: 10px;
  font-size: 26px;
  color: #444444;
  font-family: "Playfair Display";
  font-weight: 400;
}
.introLastText div {
  position: static;
  display: inline-block;
  margin-top: 49px;
  margin-right: 20px;
  flex-grow: 1;
  background-color: #444444;
  height: 2px;
}
.TopButton {
  position: fixed;
  z-index: 100;
  right: 5%;
  bottom: 10%;
  background: white;
  padding: 10px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 1px 5px 0 rgba(0, 0, 0, 0.06);
  border-radius: 50%;
  opacity: 0.5;
  transition: 0.3s all ease-in-out;
}
.TopButton:hover {
  opacity: 1;
}

.scrolling {
  opacity: 1;
}
.w-100 {
  width: 60%;
}

@media screen and (min-width: 850px) {
  .introPart1 {
    padding-top: calc(100px + 70px);
    height: calc(580px + 20px);
    overflow: hidden;
  }
  .introPart2 {
    height: 800px;
    padding-bottom: 200px;
  }
  .introPart3 {
    margin-top: -220px;
  }
  .HeadingDesign {
    float: left;
    margin-left: 40px;
  }
  .frontText {
    font-size: 52px;
    line-height: 78px;
    margin-top: -250px;
  }
  .backgroundText {
    font-size: 62px;
    line-height: 93px;
  }
  .buttonContainer {
    margin-top: 50px;
    margin-left: 5px;
  }
  .buildingImageContainer {
    float: right;
    width: 40%;
    overflow: hidden;
  }
  .buildingImageContainer img {
    width: 700px;
  }
  .workIntro {
    float: right;
    margin-top: 50px;
    margin-right: 5%;
    width: 40%;
  }
  .workIntro p {
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
  }
  .ManImageContainer {
    float: left;
    width: 50%;
    padding-top: 20px;
  }
  .ManImageContainer img {
    width: 60%;
  }
  .ManImageContainer div {
    position: absolute;
    margin-top: -23%;
    margin-left: 30%;
    width: 20%;
    background-color: #727272;
    height: 3px;
  }
  /*
  .carousel {
    margin-left: 173px;
    margin-right: 173px;
  }*/
  .carousel {
    margin-left: 10%;
    margin-right: 10%;
    height: 500px;
    width: 80%;
    overflow: hidden;
  }
  .introLastText {
    margin-left: 10%;
  }
  .introLastText p {
    font-size: 38px;
  }
  .introLastText div {
    flex-grow: 1;
    margin-right: 11.5%;
    margin-top: 65px;
  }
}

@media screen and (min-width: 900px) {
  .workIntro p {
    font-size: 18px;
    font-weight: 500;
    line-height: 35px;
  }
  .introLastText p {
    font-size: 38px;
  }
  .introLastText div {
    margin-top: 64px;
    margin-right: 11%;
  }
}

@media screen and (min-width: 1086px) {
  .introPart2 {
    height: 800px;
  }
  .buildingImageContainer {
    width: 49%;
  }
  .buildingImageContainer img {
    width: 100%;
  }
  .introLastText p {
    font-size: 48px;
  }
  .introLastText div {
    margin-top: 71px;
    margin-right: 11%;
  }
  .TopButton {
    position: fixed;
    z-index: 106;
    right: 5%;
    bottom: 10%;
    background: white;
    padding: 10px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 1px 5px 0 rgba(0, 0, 0, 0.06);
    border-radius: 50%;
    opacity: 0.5;
    transition: 0.3s all ease-in-out;
  }
  .TopButton:hover {
    opacity: 1;
  }
  .scrolling {
    opacity: 1;
  }
}
@media screen and (min-width: 1233px) {
  .buildingImageContainer {
    width: 55%;
  }
  .buildingImageContainer img {
    width: 100%;
  }
}
@media screen and (min-width: 1300px) {
  .buildingImageContainer {
    width: 58%;
    height: 440px;
  }
  .buildingImageContainer img {
    height: 100%;
  }
}
