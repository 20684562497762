h2 {
  margin: 0px;
  padding: 10px 0px;
  background-color: transparent;
}
.borderBelow {
  margin: 0em;
}
.mapouter{position:relative;text-align:right;height:500px;width:400px;}
.gmap_canvas {overflow:hidden;background:none!important;height:500px;width:400px;}
.majorContainer {
  width: 100%;
  min-height: 100vh;
  padding-top: 100px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: auto;
}
.formContainer {
  margin: 50px 150px 50px 180px;
  width: 50%;
  border: 1px solid black;
}
.Container {
  display: flex;
}
.ContactForm {
  width: 90%;
  margin-top: 20px;
  margin-bottom: 29px;
}
.ContactForm tr td {
  text-align: center;
  padding: 10px 0px;
}
.ContactForm tr td center div {
  width: 80%;
}
.ContactForm tr td p {
  text-align: left;
  margin: 0px;
  font-size: 18px;
  line-height: 40px;
  color: #747474;
}
.input {
  width: 100%;
  height: 37px;
  color: #747474;
  background-color: white;
  border: 1px solid #444444;
  padding: 0px 5%;
}
.input::-webkit-inner-spin-button,
.input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.button {
  width: 100%;
  margin-top: 30px;
  font-size: 18px;
  font-weight: 400;
  padding: 7px 0px;
  font-family: "playfair display";
  background-color: #c4c4c4;
  border: 1px solid #c4c4c4;
}
.button:hover {
  background-color: #a3a3a3;
  transition-duration: 0.4s;
}
.footer {
  display: none;
}
.TopButton {
  position: fixed;
  z-index: 100;
  right: 5%;
  bottom: 10%;
  background: white;
  padding: 10px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 1px 5px 0 rgba(0, 0, 0, 0.06);
  border-radius: 50%;
  opacity: 0.5;
  transition: 0.3s all ease-in-out;
}
.TopButton:hover {
  opacity: 1;
}
.AdContainer {
  border: 1px solid black;
  padding: 20px;
  height: 500px;
  margin-top: 100px;
  width: 500px;
  margin-right: 100px;
}
.Address {
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin: 10px;
  margin-top: 50px;
  margin-bottom: -30px;
  text-align: left;
  color: #747474;
}
.Icons {
  margin-right: 8px;
}
#val_msg {
  color: rgb(255, 72, 72);
  font-size: 0.85em;
}
/* MOBILE */

@media screen and (max-width: 650px) {
  center {
    flex-direction: column;
  }
  .formContainer {
    margin: 20px;
    width: auto;
  }
  .majorContainer {
    padding: 100px 0px;
    padding-bottom: 0px;
  }
  .AdContainer {
    margin: 60px 20px;
    padding: 20px 5px;
    width: auto;
  }
  #val_msg {
    color: rgb(255, 72, 72);
    font-size: 0.85em;
  }
  .mapouter{position:relative;text-align:right;height:500px;width:85vw;}
.gmap_canvas {overflow:hidden;background:none!important;height:500px;width:85vw;}
}
@media screen and (min-width: 700px) and (max-width: 1020px) {
  center {
    flex-direction: column;
  }
  .formContainer {
    margin: 80px;
    width: auto;
  }
  .mapouter{position:relative;text-align:right;height:500px;width:85vw;}
  .gmap_canvas {overflow:hidden;background:none!important;height:500px;width:85vw;}
  .majorContainer {
    padding: 100px 0px;
    padding-bottom: 0px;
  }
  .AdContainer {
    margin: 50px 100px;
    padding: 20px 5px;
    width: auto;
  }
  #val_msg {
    color: rgb(255, 72, 72);
    font-size: 0.85em;
  }
}
@media screen and (min-width: 900px) and (max-width: 1100px) {
  center {
    flex-direction: column;
  }
  .formContainer {
    margin: 100px;
    width: auto;
  }
  .majorContainer {
    padding: 100px 0px;
    padding-bottom: 0px;
  }
  .AdContainer {
    margin: 50px 150px;
    padding: 20px 50px;
    width: auto;
  }
  #val_msg {
    color: rgb(255, 72, 72);
    font-size: 0.85em;
  }
}
